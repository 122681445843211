import React from 'react';
import './index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


class App extends React.Component {
    
   
  componentDidMount(){
    AOS.init();
  }
    
    // Gallery
   state= {
       
       gallery:{
            0: {
                    items: 1.09,
                    margin: 5,
                    autoplay:false,
                    mouseDrag: true,
                    touchDrag:true,
                    dots:true
            },
            
            600: {
                 items: 2.35,
                    margin: 5,
                    autoplay:true,
                    autoplayTimeout:2800,
                    autoplayHoverPause:true,
                    touchDrag:true,
                    mouseDrag: true,
                    dots:true
            },
            1000: {
                 items: 4.18,
                    margin: 5,
                    mouseDrag: true,
                    dots:true,
                    autoplay:true,
                    autoplayTimeout:1900,
                    autoplayHoverPause:true,
                    
            },
            
        },
       videos:{
             0: {
                    items: 1.09,
                    nav: false,
                    margin: 5,
                    mouseDrag: true,
                    dots:false,

                },
                700: {
                    items: 1.22,
                    nav: false,
                    margin: 7,
                    mouseDrag: true,
                    dots:false,

                },

                1000: {
                    items: 3,
                    nav: false,
                    loop: false,
                    margin: 10,
                    mouseDrag: false,


                },
            
        },
   }

    render(){
   
  return (
    <div className="App">
     

    <section id="video-landing">
      <img src="img/SP-Logo-NO-TAGLINE.png" className="SP-Logo" alt="Patience Logo" title="Sincerely, Patience"/>
        <img src="https://static.clubs.nfl.com/image/private/t_q-best/eagles/ymyik9idttpwxnbivem5.png" className="white-EE" alt="Eagles Entertainment" title="Eagles Entertainment"/>

        <video id="bgvid" className="desktopVid" autoPlay loop muted playsInline>
            <source src="https://player.vimeo.com/external/385257186.hd.mp4?s=dc46f0636d042d42560c21185de6a06275903b90&profile_id=175" type="video/mp4" />
        </video>
      
        <video id="bgvid" className="mobileVid" autoPlay loop muted playsInline>
            <source src="https://player.vimeo.com/external/385259421.sd.mp4?s=5a0f657a5cda2e3de493a4e14085cddb691af3c5&profile_id=164" type="video/mp4"/>
        </video>
   
        <svg id="curveUpColor" className="svg1" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
            <path d="M0 100 C 20 0 70 80 100 100 Z" />
        </svg>

    </section>

    <main role="main" className="grid">

        <div className="col-md-12">
            <h2 className="title text-muted">Love Always Has A Game Plan</h2>
      
      <OwlCarousel
    className="owl-theme owl-theme-vids"
    loop
    lazyLoad="true"
    responsive={this.state.videos}
 >
     <div className="three-videos" data-toggle="modal" data-target="#myModal">
                    <p className="episode-number">EP1: It All Started With One Email</p>
                    <p className="vidTime">11:51</p>
                    <i className="fas fa-play-circle fa-2x"></i><img data-toggle="modal" data-target="#myModal1" src="img/1.jpg" alt="Episode 1" />
      
                </div>
      <div className="three-videos" data-toggle="modal" data-target="#myModal2">
                     <p className="episode-number">EP2: Is That Enough Bling For You?</p>
                    <p className="vidTime">17:33</p>
                    <i className="fas fa-play-circle fa-2x"></i><img data-toggle="modal" data-target="#myModal2" src="img/2.jpg" alt="Episode 2" />

                </div>
                <div className="three-videos" data-toggle="modal" data-target="#myModal3">
                     <p className="episode-number">EP3: Let’s Get Murray-ed!</p>
                    <p className="vidTime">12:50</p>
                    <i className="fas fa-play-circle fa-2x"></i><img data-toggle="modal" data-target="#myModal3" src="img/3.jpg" alt="Episode 3" />
                </div>
    
      
      </OwlCarousel>
            
        </div>
           
        <div className="col-md-12 para-margin" data-aos="fade-in" data-aos-duration="800">
          
            <p className="main-para">On June 12, 2016 the lives of Patience Carter and Alex Murray changed forever. It was on this night that Patience suffered several gunshot wounds during the Pulse nightclub shooting in Orlando, FL. Sadly, one of the people in her group that night was Akyra Murray, the youngest victim of the shooting and Alex’s sister. It’s from this tragedy and the difficult road to recovery that an unexpected relationship blossomed.</p> <p className="main-para"> Eagles Entertainment is proud to present Sincerely, Patience, a three-part docu-series about a young, resilient couple who fell in love amid tragedy and are now ready to begin their lives together while honoring the memory of Alex’s sister. This wedding journey begins with an email to the Eagles and culminates on the 50-yard line, with moments that will make you laugh and cry along the way.</p>
            
        </div>
      

    
      
    </main>

    <section data-aos="fade-in" data-aos-duration="2000" className="mobile-section">

    </section>
    <div className="desktop-pat">
        <img src="https://static.clubs.nfl.com/image/private/t_q-best/eagles/oqwcarnhtilwvbadwxth.jpg" width="100%;" data-aos="fade-in" data-aos-duration="2000" className="desktop-section" alt="Couple Hug" title="Alex & Patience" loading="lazy" />

    </div>
           
      <OwlCarousel
    className="owl-theme"
    loop
    lazyLoad="true"
    responsive={this.state.gallery}
 data-aos="fade-in" data-aos-duration="800">
    
        <img className="owl-lazy" data-src="img/gallery/1.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/2.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/3.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/4.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/5.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/6.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/7.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/8.jpg" alt="Gallery" title="Image Gallery" />
        <img className="owl-lazy" data-src="img/gallery/9.jpg" alt="Gallery" title="Image Gallery" />
</OwlCarousel>
  <div className="container-fluid">
    <div className="row couple-row">


        <div className="col-md-3 col-lg-4 col-xl-2">
            <img src="img/pat-bio.jpg" className="bio-img" data-aos="fade-in" data-aos-duration="800" alt="Patience Carter" title="Patience Carter"/>
        </div>
        <div className="col-md-9 col-lg-8 col-xl-4 Patience" data-aos="fade-in" data-aos-duration="800">
            <h3 className="headings"><span>Patience Carter</span></h3>
            <p className="patience-text">Patience Carter is an entrepreneur, author, and Outreach Director for the Gun Violence Survivors Foundation. She is a survivor of the Pulse nightclub shooting, which is considered to be one of the deadliest mass shootings by a single person in modern U.S. history. Since then, Patience has sat down with a countless number of U.S. officials to speak about gun control, terrorism, and mental health issues in America. The Teen Choice Awards presented her with their Courage Award in 2016 and she has received a special recognition certificate from Connecticut's Senator, Richard Blumenthal, for her extraordinary dedication and exemplary efforts to reduce violence. She has been featured by several media outlets including interviews with Glamour, Teen Vogue and CNN. Patience currently resides in Florida, but continues to travel the country to speak out about her experience. She is set to graduate from NYU in Spring 2020 with a degree in Media, Culture & Communication.</p>
  
        </div>

        <div className="col-md-3 col-lg-4 col-xl-2" data-aos="fade-in" data-aos-duration="800">
            <img src="img/alex-bio.jpg" className="bio-img" alt="Alex Murray" title="Alex Murray"/>
        </div>
        <div className="col-md-9 col-lg-8 col-xl-4 Alex" data-aos="fade-in" data-aos-duration="800">
            <h3 className="headings"><span>Alex Murray</span></h3>
            <p className="alex-text">Alex Murray is an entrepreneur and content creator who was born and raised in Philadelphia, PA – and a huge Eagles fan. He also is the Event Coordinator for the Akyra Murray Memorial Basketball Game, hosted annually in Philadelphia in honor of his sister, that is comprised of the top basketball players in the Greater Philadelphia region. In high school Alex was a football star in his own right, having been selected to compete in the 2012 City All-Star Football Game – a scrimmage between the 100 top high school football players in Philadelphia. The game was held at Lincoln Financial Field that year and would be the last official football game he ever played in due to suffering a career-ending injury his freshman year of college. Alex now resides in Florida where he is focused on launching his own delicious frozen treat business.</p>
        </div>
    </div>
      </div>
<div className="col-md-12 main-para text-center" >
 
      For media inquires contact <br />
      <a href={"mailto:abonagura@eagles.nfl.com"} className="email-link"> abonagura@eagles.nfl.com <i className="far fa-envelope"></i></a>
    </div>
    <svg id="curveUpColor2" className="svg2" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C 20 0 70 80 100 100 Z" />
    </svg>
    <footer>
      <div className="col-md-12">
            <center>
                <img src="https://static.clubs.nfl.com/image/private/t_q-best/eagles/ymyik9idttpwxnbivem5.png" className="white-EE-footer" alt="Eagles Entertainment" title="Eagles Entertainment" />
                         <p className="legal-copy">© 2021 Philadelphia Eagles</p>
             </center> 
        </div>
    </footer>

    <div className="modal fade" id="myModal" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered">

            <div className="modal-content">
      

                <div className="modal-body">
                      <iframe src="https://player.vimeo.com/video/392224091" frameBorder="0" allow="autoplay;" allowFullScreen id="video1" className="trailer" title="Episode 1"></iframe> 
     

                </div>
              
            </div>

        </div>
    </div>

 
    <div className="modal fade" id="myModal2" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered">

        
       <div className="modal-content">
              
                <div className="modal-body">
                       <iframe src="https://player.vimeo.com/video/390816072" frameBorder="0" allow="autoplay;" allowFullScreen id="video1" className="trailer" title="Episode 2"></iframe> 
           
                        
                    
                </div>
              
            </div>

        </div>
    </div>
      
         <div className="modal fade" id="myModal3" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered">

     
       <div className="modal-content">
                
                <div className="modal-body">
                        <iframe src="https://player.vimeo.com/video/391001690" frameBorder="0" allow="autoplay;" allowFullScreen id="video1" className="trailer" title="Episode 3"></iframe> 

                </div>
              
            </div>

        </div>
    </div>
    
    </div>
    );
  }
    
}

export default App;
